@import "./color-style.scss";
@import "./support.scss";

.project-title {
  font-size: $font-size-xxl;
  line-height: normal;
  margin-bottom: 0;
}

.ant-table {
  background: transparent;
}

.ant-table-cell {
  color: $baseWhite;
}

.ant-select-selection-item {
  color: $baseWhite;
}

.ant-select-item-option-content {
  color: $baseWhite;
}

.ant-input {
  color: $baseWhite;
}

.ant-btn-link {
  //instead of deepRed
  color: $baseWhite;
  font-size: medium;
}

.ant-tooltip-inner {
  background-color: #25050a;
}

.export-link {
  color: $baseWhite;

  &:hover {
    color: $baseWhite;
  }

  .anticon-close {
    position: relative;
    bottom: 2px;
  }
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: none !important;
}

.pipeline-table {
  padding: 0 30px;
  padding-bottom: 30px;

  .ant-table-tbody {
    tr {
      td {
        padding: 10px !important;
        font-size: 14px;
        background: transparent !important;
      }

      td:first-child {
        padding-left: 20px !important;
      }

      //   td:last-child {
      //     flex: 1;
      //     display:inline-block;
      // }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: .2s linear;
    color: $baseRed;
    // &:hover {
    //   color: $baseWhite;
    // }
  }
}

//
.pricing-table {
  padding: 0px;
  padding-bottom: 30px;

  .ant-table-tbody {
    tr {
      td {
        padding: 10px !important;
        font-size: 14px;
        background: transparent !important;
      }

      td:first-child {
        padding-left: 10px !important;
      }

      //   td:last-child {
      //     flex: 1;
      //     display:inline-block;
      // }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: .2s linear;
    color: $baseRed;
    // &:hover {
    //   color: $baseWhite;
    // }
  }
}

//pricing
.project-detail-collapse {
  .ant-collapse-content>.ant-collapse-content-box {
    padding: 16px;
    height: 80px !important;
  }
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  padding: 10px 0 10px 10px;
  color: hsla(0, 0%, 100%, .85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all .3s;

  .ant-collapse-header-text {
    flex: none;
    // padding-left: 30px;
  }
}

.project-sub-title {
  font-size: medium;
  line-height: normal;
  margin-bottom: 0;
}

.col-content-inner {
  min-width: 180px;
  padding: 0 8px;
  min-width: 180px;
}

.project-details-wrapper {
  font-size: $font-size-base;
  color: $baseWhite;

  .title {
    color: $baseWhite;

    .anticon-close {
      color: $baseWhite;
      cursor: pointer;
    }
  }
}

.edit-project-detail {
  cursor: pointer;
}

.edit-project-detail-disable {
  cursor: not-allowed;
}

.edit-session {
  height: 32px;
  align-items: center;

  .ant-picker {
    background: $input-bg !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    height: 32px;
  }

  .ant-btn-primary {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 45px;
  }

  .ant-input {
    max-width: 150px;
  }

  .ant-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-input-group-compact {
    .ant-input {
      height: 32px;
    }
  }

  .ant-input-group>.ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  .ant-input-group.ant-input-group-compact>*:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

.name-max-width {
  max-width: 180px;
  min-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//pricing equipment table
.equipment-layout {
  // height: 100%;
  width: 100%;
}

.equipment-content {
  padding: 2px;
  // overflow-y: auto;
  font-size: 12px;
}

.equipment-card {
  background-color: transparent;
  border-radius: 8px;
  padding: 0px;
  height: 100%;
  color: white;

  .ant-card-body {
    padding: 10px;
  }
}

.equipment-row {
  margin-bottom: 3px;

  .equipment-column {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .equipment-name {
      width: 70%;
      font-weight: 300;
      font-size: 12px;
    }

    .equipment-qty {
      width: 30%;
      font-size: 12px;
      // text-align: center;
    }
  }
}

.ant-layout-footer {
  padding: 5px 0px;
}

.equipment-footer {
  text-align: center;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  // position: sticky;

  button {
    margin-right: 10px;
    border-radius: 30px;
  }
}

.pricing-equipment-table {
  padding-bottom: 30px;

  // .ant-table-tbody tr.ant-table-row:nth-child(even){
  //   background: none;
  // }
  .ant-table-tbody {
    tr {
      td {
        font-size: 12px;
      }

      td:first-child {
        padding-left: 10px !important;
      }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: .2s linear;
    color: $baseRed;
  }
}

.Data360-pricing-table {


  tr:nth-child(even) td:last-child,
  tr:nth-child(even) td:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 00px;
  }

  tr:nth-child(even) td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-table-cell {
    border: 1px solid rgb(24, 22, 22);
    /* Add borders to all sides */
    font-size: 18px;
    font-weight: bold;
    /* Increase font size */
    padding: 12px;
    /* Adjust padding for table cells */
  }

  .ant-table-tbody tr.ant-table-row {
    border-radius: 0;
  }

  .ant-table-thead>tr>th {
    font-size: 24px;
    /* Increase font size for headers */
    background-color: transparent;
    font-weight: bold !important;
    border: 1px solid rgb(120, 118, 118);
    color: $baseRed;
    /* Ensure headers have borders as well */
  }

  .ant-table-tbody>tr>td {
    border: 1px solid rgb(120, 118, 118);
    /* Add borders to table body */
  }

  .ant-table-tbody tr.ant-table-row td {
    border: 1px solid rgb(120, 118, 118);
  }

  /* Optionally, make the table itself bigger or full-width */
  .ant-table {
    width: 100%;
    border-collapse: none;
    //border: 1px solid rgb(120, 118, 118);
    /* Make table take up the full width */
  }

}

.Data360-addbefore {
  .ant-input-group-addon {
    border: none;
    background-color: #FFFFFF1A !important;
    //border-radius:'20px' !important;
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important
  }

  .ant-input-group .ant-input {
    text-align: center;
  }

  /* CSS for the 0% mark */


}




.Data360-addafter {
  .ant-input-group-addon {
    border: none;
    background-color: #FFFFFF1A !important;
    //border-radius:'20px' !important;
    border-bottom-Right-radius: 20px !important;
    border-top-Right-radius: 20px !important
  }
}

.Data360-addbefore1 {
  .ant-input {
    border-radius: 0px !important;
    border-bottom-Right-radius: 5px !important;
    border-top-Right-radius: 5px !important
  }

  .ant-input-group-addon {
    border: none;
    background-color: #FFFFFF1A !important;
    //border-radius:'20px' !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important
  }

  .ant-input-group .ant-input {
    text-align: left;
  }

  /* CSS for the 0% mark */


}



.Data360-addafter1 {

  .ant-input {
    border-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important
  }

  .ant-input-group-addon {
    border: none;
    background-color: #FFFFFF1A !important;
    //border-radius:'20px' !important;
    border-bottom-Right-radius: 5px !important;
    border-top-Right-radius: 5px !important
  }
}

.pricing-calculation-table {
  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: none;
  }

  .ant-table-row {
    margin: 0;
    padding: 20px 0;
    border: 1px solid #303030;
  }

  .ant-table-cell {
    padding: 4px;
  }

  // .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  //   border: 1px solid transparent;
  // }
}

.equipment-calculation {
  .calculation-row {
    .ant-form-item-label {
      label {
        color: $baseRed;
      }
    }
  }
}


.table-head-yellow {
  tr {
    th {
      color: $baseRed;
      font-weight: 400;
    }
  }
}

.ant-table-thead {
  font-size: $font-size-base;

  tr {
    th {
      background: transparent;
      border-bottom: none;
      vertical-align: top;
      white-space: nowrap;
      font-weight: 500 !important;

      &.ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }
  }
}

.quality-report-table {
  .ant-table-thead {
    tr {
      th {
        white-space: normal;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        white-space: normal !important;
      }
    }
  }
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}

.pricing-fixed-Table {



  ant-table-cell-fix-left,
  .ant-table-cell-fix-right {

    .ant-table-body tr.ant-table-row:hover {
      background: #3b1c20 !important;
    }

    background: #3b1c20;

    &:hover {
      background: #3b1c20 !important;
    }

    /* Prevent hover effects on fixed columns rows */
    .ant-table-body tr.ant-table.ant-col:hover & {
      background: #3b1c20 !important;
      /* Keep the background color unchanged on row hover */
    }
  }


  tr:nth-child(even) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;

      &:hover {
        background: #39201d !important;
      }
    }
  }

  tr:nth-child(odd) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;

      &:hover {
        background: #3f0a0c !important;
      }
    }
  }

  tr.ant-table-row:hover:nth-child(odd) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;
    }
  }

  tr.ant-table-row:hover:nth-child(even) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;
    }
  }
}


.ant-table-ping-right .ant-table-cell-fix-right-first::after {
  box-shadow: none !important;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none !important;
}


.recharts-legend-wrapper {
  position: relative !important;
}

.ant-modal {
  .ant-modal-footer {
    border-top: none;
    padding: 0;

    .ant-btn {
      padding: 8px 20px;
      height: 40px;
      font-weight: 700;
      background: transparent;
      color: $baseWhite;
      border-color: $baseWhite;
      border-radius: 30px;
    }

    .ant-btn-primary {
      background: $baseRed;
      color: $baseWhite;
      border-color: $baseWhite;
    }
  }

  .ant-table {
    background: transparent !important;
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent !important;
        border-bottom: none !important;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }

      .main-title {
        background: #FFFFFF1A 0% 0% no-repeat padding-box !important;
        border-radius: 46px !important;
        color: $baseRed;
      }
    }
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      &:nth-child(even) {
        background: $table-bg-white !important;
      }

      &:hover>td {
        background: transparent !important;
      }

      td {
        border-bottom: none !important;
        border: none;
      }
    }
  }
}

.ant-table-tbody {
  tr.ant-table-row {
    border-radius: 30px;

    &:nth-child(even) {
      background: $table-bg-white;
    }

    &:hover>td {
      background: transparent !important;
    }

    td {
      border-bottom: none;
      vertical-align: top;
      // white-space: nowrap;
    }
  }
}

tr:nth-child(even) {
  td:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  td:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}

.ant-table-tbody>tr>td {
  border-bottom: none;
}

.ant-drawer-logo {
  // background-image: url(/static/media/fsa_logo.cd86a2a2.jpg);
  background-repeat: round;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-top: 0px;
}

.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background: transparent;
  // flex: 0 0 230px !important;
  // max-width: 230px !important;
  // min-width: 230px !important;
  // width: 230px !important;
}

.ant-layout-sider-trigger {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000a3 100%) 0% 0% no-repeat padding-box;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-inline::after {
  border-right: none;
}

.left-menu {
  background: transparent;
  border-right: none;

  .ant-menu-item {
    font-size: $font-size-base;

    &::after {
      content: none;
    }

    &:hover {
      color: $baseRed;
    }

    &:active {
      background: transparent !important;
    }

    a {
      &:hover {
        color: $baseRed;
      }
    }

    .ant-btn-link {
      padding-left: 0;
      color: $text-color-white;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        color: $baseRed;
      }
    }
  }

  .ant-menu-item-selected {
    color: $baseRed;

    a,
    .ant-btn-link {
      color: $baseRed;

      &:active {
        background: transparent !important;
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.logo-container {
  padding-left: 24px;
  padding-top: 24px;

  .aside-logo-small {
    display: none;
    cursor: pointer;
  }

  .logo-wrapper {
    max-width: calc(200px - 48px);

    .aside-logo-large {
      width: calc(200px - 48px);
      display: grid;

      // .logo-black {
      //   display: none;
      // }

      img {
        width: 120px;
        margin-left: 20px;
      }

      .logo-text {
        position: relative;
        top: 8px;
        font-size: 14px;
        font-weight: 800;
        color: $baseRed;
        text-shadow: 1px 1px 0px #ef314c7d;
      }
    }

    .aside-logo-small {
      // width: 39px;

      img {
        width: 60px;
      }
    }
  }
}

.user-name {
  p {
    color: #FFFFFF99;
  }

  .ant-avatar {
    color: #ef314c;
  }
}

.first-last-letter {
  display: none;
}

.full-name {
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.user-logo {
  position: relative;
  right: 14px;
  top: 10px;
  // left: 0;
  cursor: pointer;
}

.ant-layout-sider-collapsed {
  .aside-logo-small {
    display: block;
    margin: auto;
  }

  .aside-logo-large {
    display: none !important;
    // width: 100%;
  }

  .poweredby,
  .menu-content {
    opacity: 0;
  }

  .first-last-letter {
    display: block;
  }

  .full-name {
    display: none;
  }

  .user-logo {
    left: 2px;
  }

  .logo-container {
    padding-left: 0;
    text-align: center;
  }
}

.ant-layout-header {
  background: transparent;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 50px;
}

//silder
.flexslider {
  border-radius: 14px;
  overflow-x: hidden;

  .rec-item-wrapper {
    max-width: 212px;
  }

  .rec-carousel {
    position: relative;
    overflow: hidden;
    // margin-bottom: 16px;
  }

  .rec-arrow {
    position: absolute;
    top: 0;
    border: none;
    height: 106px;
    box-shadow: none;
    border-radius: 0;
    color: #ffffff89;
    z-index: 1;
  }

  .rec-arrow-left {
    left: 0;
    background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-arrow-right {
    right: 0;
    background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0% no-repeat padding-box;
      box-shadow: none;
    }
  }

  .rec-slider-container {
    margin-left: 46px;
    margin-right: 36px;
  }

  // .eWjCzc:hover:enabled, .eWjCzc:focus:enabled{
  //   background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box;
  //   box-shadow: none;
  // }
  // .hJJzxj:hover:enabled, .hJJzxj:focus:enabled{
  //   background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box;
  //   box-shadow: none;
  // }
}

.slides {
  height: 106px;
  overflow: hidden;
  width: 100%;

  .slides-items {
    background: $card-bg-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    height: 100%;
    list-style: none;
    padding: 15px;
    margin-right: 16px;
    color: $baseWhite;
    font-size: $font-size-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 212px;

    &.active {
      background: $baseRed 0% 0% no-repeat padding-box;
      color: $baseWhite;
      font-weight: 600;
    }
  }
}

//project details
.project-details-wrapper {
  font-size: '18px';
  color: $baseWhite;

  .title {
    color: $baseRed;

    .anticon-close {
      color: $baseWhite;
      cursor: pointer;
    }
  }
}

//other Details
.other-details {
  .detail-rows {
    text-align: left !important;
    margin-bottom: 15px;
  }
}

// tab
.custom-tab {
  font-size: $font-size-base;

  .ant-tabs-nav {
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 6px;

    &::before {
      border-bottom: none;
    }


  }

  .ant-tabs-content-holder {
    border-left: none;
  }

  .ant-tabs-ink-bar {
    display: none;
    //background-color: transparent;
  }

  .ant-tabs-tab {
    padding: 5px 25px;
    border-radius: 30px;
    font-size: $font-size-lg;
    color: $baseRed;
    font-weight: 500;
  }

  .ant-tabs-tab-active {
    background: $baseRed;

    .ant-tabs-tab-btn {
      color: $baseWhite;
      font-weight: 500;
    }
  }

  .ant-tabs-tab-btn:active {
    //instead of deepRed
    color: $baseWhite;
  }
}

//divider
.custom-line {
  width: 100%;
  height: 1px;
  background: $Grey;
}

.border-right {
  border-right: 1px solid $Grey;
}


.pipeline-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $baseRed;
  }

  .ant-modal-header {
    border-color: white
  }

  .ant-select-arrow {
    top: 70%;
    color: $baseRed;

    .ant-select-selection-item {
      color: $baseRed;
    }
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ef314c;
  }



  .ant-checkbox-wrapper,
  .ant-checkbox-inner,
  .ant.ant-checkbox-wrapper,
  .ant-checkbox,
  .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #ef314c;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ef314c;
    border-color: #ef314c;
  }

  .ant-checkbox-checked::after {
    border: 3px solid #ef314c;
  }


  .ant-input[disabled] {
    color: white;
  }

  .ant-checkbox-wrapper {
    border-radius: 20px;
  }
}

.trackrecord-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $baseRed;
  }

  .ant-select-arrow {
    top: 50%;
    color: $baseRed;

    .ant-select-selection-item {
      color: $baseRed;
    }
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ef314c;
  }



  .ant-checkbox-wrapper,
  .ant-checkbox-inner,
  .ant.ant-checkbox-wrapper,
  .ant-checkbox,
  .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #ef314c;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ef314c;
    border-color: #ef314c;
  }

  .ant-checkbox-checked::after {
    border: 3px solid #ef314c;
  }


  .ant-input[disabled] {
    color: white;
  }

  .ant-checkbox-wrapper {
    border-radius: 20px;
  }
}

.pipeline-modal2 {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $baseRed;
  }

  .ant-select-arrow {
    top: 70%;
    color: $baseRed;

    .ant-select-selection-item {
      color: $baseRed;
    }
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: gray;
  }

  .ant-checkbox-inner {
    background-color: gray;
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-inner,
  .ant.ant-checkbox-wrapper,
  .ant-checkbox,
  .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: gray;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: gray;
    border-color: gray;
  }

  .ant-checkbox-checked::after {
    border: 3px solid gray;
  }


  .ant-input[disabled] {
    color: white;
  }

  .ant-checkbox-wrapper {
    border-radius: 20px;
  }
}


.pipeline-btn {
  display: inline-flex;
  position: flex;
  right: 60px;

  .cancel-btn {
    color: #fff;
    border-radius: 25px;
    border: 1px solid $baseWhite;
    font-weight: 500;
    transition: .3s linear;
  }

  .submit-btn {
    background: $baseRed;
    color: #fff;
    border-radius: 25px;
    border: 1px solid $baseWhite;
    font-weight: 500;
    transition: .3s linear;

  }

}

.Generate-btn {
  display: inline-flex;
  position: fixed;
  right: 60px;

  .cancel-btn {
    color: #fff;
    border-radius: 25px;
    border: 1px solid $baseWhite;
    font-weight: 500;
    transition: .3s linear;

  }

  .submit-btn {
    margin-left: 20px;
    background: $baseRed;

    color: #fff;

    border-radius: 25px;
    border: 1px solid $baseRed;
    font-weight: 500;
    transition: .3s linear;


  }

}

.custom-fill {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;

  .new-pipeline {
    position: relative;
    margin: 0 0 0 auto;
    padding-right: 20px;
    text-align: right;
  }

  .weldNumber {
    margin: 0 0 0 auto;

    .ant-btn {
      background: #ef314c;
      border-radius: 25px;
      font-weight: 500;
      transition: .2s linear;
      border: 1px solid $baseRed;

      &:hover {
        border: 1px solid $baseRed;
        background: transparent !important;
        color: $baseRed;
      }

      &:focus,
      &:active {
        color: $baseWhite;
      }
    }
  }

  .add-new-pipeline {
    // position: absolute;
    // right: 36%;
    cursor: pointer;
    transition: .2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }

}

.checkbox-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding-right: 50px;
}

.checkbox-row {
  display: flex;
  margin-bottom: 50px;

}

.ant-input-number-input {
  text-align: center;
}

.ant-input-number-handler-wrap {
  background: rgba(255, 255, 255, 0.1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

// WeldDesignDashboard.scss
.weld-design-dashboard {
  // height: calc(100vh - 130px);
  // overflow-y: auto;
  padding: 4px 2px 2px 2px;
  background-color: transparent;

  .ant-card-body {
    padding: 14px;
  }

  .ant-table-thead {
    tr {
      th {
        color: $baseRed;
      }
    }
  }

  .card-wrapper {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .info-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        background-color: transparent;
      }
    }

    .ant-card-head-title {
      // color: $baseRed; 
    }
  }

  .weld-design-card {
    border-radius: 8px;
    border-color: #d9d9d9;
    background-color: transparent;
    margin-bottom: 8px;

    .bevel-design {
      text-align: center;
      background-color: transparent;

      .icon {
        font-size: 100px;
        color: #1890ff;
      }
    }

    .info-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        background-color: transparent;
      }
    }

    .ant-card-head-title {
      // color: $baseRed; 
    }
  }

  .table-wrapper {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .ant-card-head-title {
      // color: $baseRed; 
    }
  }

  .consumable-calculations {
    background-color: transparent;
    border-color: #d9d9d9;

    .calc-card {
      background-color: transparent;

      .ant-table-small {
        background-color: transparent;
      }
    }

    .ant-card-head-title {
      // color: $baseRed; 
    }
  }

  // Additional classes for other elements
  .ant-card {
    background-color: transparent;
  }

  .ant-table {
    background-color: transparent;
  }

  .ant-tooltip {
    background-color: transparent;
  }
}

//internal external and m500
.card-container {
  display: flex;
  justify-content: space-around; // Ensures the cards are spaced evenly
  // flex-wrap: wrap; // Allows wrapping for responsive design
  padding: 15px 0;

  // Card styling
  .ant-card {
    width: 100%; // Ensures that cards take 30% of the available width
    // margin: 2px;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: transparent;

    .ant-card-head-title {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }

    .wire-info-row {
      margin-bottom: 16px;
      text-align: left;

    }
  }
}

//bavel configuration
.bevel-design-row {
  margin-right: 130px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  .bevel-design-key {
    border-right: 1px solid #d9d9d9;
    padding-right: 10px;
    padding-top: 20px;
  }

  .bevel-design-value {
    padding-left: 10px;
    padding-top: 16px;

    .ant-select-selector {
      border-radius: 25px !important;
      border: none !important;
      background: #FFFFFF1A !important;
      height: 100% !important;
    }

    .ant-select-selection-overflow {
      padding: 12px;

      .ant-select-selection-item {
        border-radius: 25px;
        color: $baseWhite;
        width: 96%;
        padding: 12px;
      }
    }

    .ant-select-selection-item {
      padding: 4px;
    }
  }
}

//bevel-design
.bevel-select {
  .ant-select-selector {
    border-radius: 5px !important;
    width: 60%;
  }

  .ant-select-selection-item {
    position: absolute !important;
    padding: 5px !important;
    color: white;
    top: -3px;
  }

  .ant-select-selector {

    border: none !important;
    background: #FFFFFF1A !important;
    // height: 38px !important;

    &:focus-visible {
      outline: none !important;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      top: 1px;
    }

    .ant-select-selection-search {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      // width: 100%;
      min-width: 0;
      padding: 4px 0;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border-radius: 2px;
      transition: all 0.3s;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ant-select-selection-search-input {
      padding: 6.5px 0px !important;
      font-size: 16px;
      height: 32px !important;
      color: $baseWhite;
    }
  }

  .ant-select-arrow {
    color: $baseRed;

    .ant-select-selection-item {
      color: $baseRed;
    }
  }
}

.diameter-select {
  .ant-select-selector {
    border-radius: 5px !important;

    &:focus-visible {
      outline: none !important;
    }
  }

  .ant-select-selection-item {
    position: absolute !important;
    color: white;
  }

  .ant-select-selector {

    border: none !important;
    background: #FFFFFF1A !important;

    .ant-select-selection-placeholder {
      position: absolute;
    }

  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseRed;
  }
}

//welding modal width
.welding-modal-width {

  // For larger screens (default)
  .ant-modal {
    width: 90vw !important;
  }

  @media screen and (max-width: 768px) {

    // For smaller screens
    .ant-modal {
      width: 100vw !important;
    }
  }
}



//fcaw-g
.fcaw-g-tab {
  padding: 4px 2px 2px 2px;
  background-color: transparent;

  .ant-table-thead {
    tr {
      th {
        color: $baseRed;
      }
    }
  }

  .ant-card-body {
    padding: 14px;
  }

  .info-card {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .ant-card-head-title {
      // color: $baseRed; 
    }

    .ant-table {
      background-color: transparent;
    }
  }

  .card-wrapper {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;
  }

  .params-card {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .ant-card-head-title {
      // color: $baseRed;
    }

    .ant-table {
      background-color: transparent;
    }
  }

  .consumption-card {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .ant-card-head-title {
      // color: $baseRed;
    }

    .ant-table {
      background-color: transparent;
    }
  }

  .bevel-card {
    margin-bottom: 8px;
    background-color: transparent;
    border-color: #d9d9d9;

    .ant-card-head-title {
      // color: $baseRed;
    }

    // Custom style for the SVG rendering
    div {
      text-align: center;
      background-color: transparent;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .ant-table {
    background-color: transparent;
  }
}




//card
.custom-card {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  background: #ef314c;
  color: white;
  font-weight: 500;
  border: 1px solid #ef314c;
  transition: .3s linear;


  .ant-card-body {
    padding: 30px 6px 30px 6px;
    font-size: 16px;
    width: 200px;
    height: 100px;

  }

  .anticon {
    color: $baseWhite;
    font-size: 30px;
    border-radius: 25px;
    border: 1px solid;
    margin: 0 10px;

    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
  }



  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.custom-card2 {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  background: #ef314c;
  color: white;
  font-weight: 500;
  border: 1px solid #ef314c;
  transition: .3s linear;


  .ant-card-body {
    padding: 10px;
    font-size: 16px;

  }

  .ant-card-head-title {
    font-size: 20px;
    position: absolute;
    padding-top: 35px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '20px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.custom-card5 {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  background: #ef314c;
  color: white;
  font-weight: 500;
  border: 1px solid #ef314c;
  transition: .3s linear;

  .ant-row {
    margin-bottom: 16px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-radio-group {
    display: inline-flex;
  }

  .ant-card-body {
    padding: 10px;
    font-size: 16px;

  }

  .ant-card-head-title {
    font-size: 20px;
    position: absolute;
    padding-top: 35px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '20px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.custom-card3 {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  background: #ef314c;
  color: white;
  font-weight: 500;
  border: 1px solid #ef314c;
  transition: .3s linear;


  .ant-card-body {
    padding: 10px;
    font-size: 16px;

  }

  .ant-card-head-title {
    font-size: 20px;
    position: absolute;
    padding-top: 35px;
    width: 100%;
    padding-right: 40px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '20px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.Cashflow-custom-card {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 15px;
  margin-top: 0px;
  cursor: pointer;
  //background: #ef314c;
  color: white;
  font-weight: 500;
  border: 1px solid #ef314c;
  transition: .3s linear;
  background: rgba(255, 255, 255, 0.1);
  //border-color: 'rgba(255, 255, 255, 0.1)';

  .ant-card-body {
    padding: 10px;
    font-size: 16px;

  }

  .ant-card-head-title {
    font-size: 20px;
    position: absolute;
    padding-top: 35px;
    width: 100%;
    padding-right: 40px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '20px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }



  .ant-checkbox-wrapper,
  .ant-checkbox-inner,
  .ant.ant-checkbox-wrapper,
  .ant-checkbox,
  .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #ef314c;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ef314c;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ef314c;
    border-color: #ef314c;
  }

  .ant-checkbox-checked::after {
    border: 3px solid #ef314c;
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.Prcing-card2 {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  background: transparent;
  color: white;
  font-weight: 500;
  //border: 1px solid #ef314c;
  transition: .3s linear;


  .ant-card-body {
    padding: 10px;
    font-size: 16px;

  }

  .ant-card-head-title {
    font-size: 20px;
    position: absolute;
    padding-top: 50px;
    margin-left: -10px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '10px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.Data360_Prcing-card {
  //background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
  background: transparent;
  color: white;
  font-weight: 200;
  border: 1px solid #ef314c;
  transition: .3s linear;


  .ant-card-body {
    padding: 10px;
    font-size: 10px;

  }

  .ant-card-head-title {
    font-size: 16px;
    position: absolute;
    padding-top: 50px;
    margin-left: -10px;

  }

  .ant-card-head {
    border-color: #ef314c;
    padding-bottom: '10px';
  }

  .anticon {
    color: $baseRed;

    &:hover {
      background-color: $baseWhite;
    }
  }

  .ant-card-actions {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #1e0d0f;
  }

  &:focus,
  &:active {
    color: $baseWhite;
  }
}

.card-height {
  height: 96px;
}

.theme-color {
  color: $baseRed !important;
}

.create-project {
  background: $baseRed;
  color: $baseWhite;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
  margin-right: 16px;
  // margin-bottom: 16px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: pointer;
}

.small-text {
  font-size: 13px;
  color: $text-color-white;
}

//scroll bar
::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(43, 43, 43);
  outline: 1px solid rgb(46, 46, 46);
  border-radius: 10px;

  // background-color: #ef314cba;
  //   outline: #ef314cba;
  //   border-radius: 10px;
}

// typo
h4.ant-typography {
  font-weight: 500;
}

//modal
.scroll-table {
  height: calc(100vh - 300px);
  overflow: auto;
}

.ant-modal-close {
  display: none;
}

.ant-modal-content {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 14px;
  padding: 2rem;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }

  //  *{
  //   position: relative;
  //   z-index: 2;
  // }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .ant-modal-content {
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0);
  }
}

.ant-modal-header {
  background: transparent;
  padding: 0 0 16px 0;
  border-color: #707070;

  .ant-modal-title {
    color: $baseRed;
  }
}

.align-bottom {
  vertical-align: bottom !important;
}

.hidden-view-btn {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 100%;
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 96%;
  }
}

//chart
.chart-wrapper {
  >* {
    height: 100% !important;
  }
}


.ant-input {
  border: none;
  background: $input-bg !important;
  border-radius: 20px;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border: none !important;
  box-shadow: none
}

.ant-btn-primary {
  height: 32px;
  background: $baseRed;
  color: $baseWhite;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: $baseRed;
    color: $baseBlack;
  }
}

.rTableCell {
  text-align: left;
}

// .welds-modal{
//   .ant-modal-body{
//     height:76vh;
//     overflow: auto;
//   }
// }
#components-form-demo-control-ref .ant-btn {
  margin-right: 8px;
}

.quality-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    border-color: $baseRed;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseRed;
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.ant-modal-body {
  padding: 24px 0;
}

.ant-picker-focused,
.ant-picker {
  background: $input-bg;
  border: none;
  box-shadow: none;
  height: 38px;
  border-radius: 20px;
  width: 100%;
}

.ant-form-item-label>label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label>label.ant-form-item-required::before {
  content: none !important;
}

.search-container {
  height: 40px;

  .ant-btn {
    height: 30px;
    min-width: 30px !important;

    .anticon {
      position: relative;
      bottom: 4px;
    }
  }
}

.search-wrapper {
  max-width: 380px;
  padding-left: 30px;

  .ant-input {
    height: 32px;
    border-radius: 0;
  }

  .ant-input-search {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    background: $input-bg !important;

    .ant-input {
      background: transparent !important;
    }
  }

  .ant-input-search-button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
  }
}

.search-top {
  border: none;
  color: $baseWhite;
  background: $baseRed;

  // border-top-right-radius:20px;
  // border-bottom-right-radius:20px;
  // border-top-left-radius:0px;
  // border-bottom-left-radius:0px;
  &:hover,
  &:focus,
  &:active {
    color: $baseWhite;
    background: $baseRed;
  }

  .anticon-search {
    bottom: 3px !important;
  }

  .ant-input-search-button {

    height: 32px;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: $baseRed;
  }
}

.report-table {
  .ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nowrap {
    white-space: nowrap;
  }

  .ant-table-thead {
    position: sticky;
    top: -1px;
    z-index: 3;
    background: rgb(36, 28, 28);
    background: linear-gradient(90deg, rgba(36, 28, 28, 1) 0%, rgba(58, 47, 34, 1) 100%);

    tr:first-child th:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.data-listing-table {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

// Login
.login-bg {
  background: url(../assets/loginbg.png);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  height: 100vh;

  &::before {
    content: '';
    background: transparent linear-gradient(180deg, #000000B0 0%, #0000001F 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    right: 0;
  }

  .logo_main {
    position: absolute;
    left: 1.5rem;
    width: 200px;
  }
}

.login_copy {
  position: absolute;
  color: #80808094;
  font-size: 16px;
  font-weight: 500;
  bottom: 10px;
  // margin: 0 auto;
  text-align: center;
  margin: 0px 30px;

  a {
    color: #80808094;
  }
}

.reset-form {

  .ant-input-password,
  .login-form-button {
    width: 96% !important;
  }
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  h1 {
    color: $baseWhite;
    font-weight: 700;
  }

  .ant-input {
    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
  }

  .password-icon {
    border: none !important;
    height: 50px;
    background: $input-bg !important;
    padding: 0;
    border-radius: 30px;

    .ant-input {
      border: none !important;
      background: none !important;
      padding: 6.5px 18px;

      &:hover,
      &:focus,
      &:active {
        border: none !important;
      }
    }

    .ant-input-suffix {
      padding-right: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}


.resend-link {
  width: 50vw !important;

  .resendLink {
    font-size: 14px !important;
    border: 0 !important;
    margin: 0 !important;
    color: #ef314c !important;
    padding-top: 0 !important;
    padding-left: 3px !important;
    margin-left: 5px !important;
    font-weight: 500 !important;
    background: none !important;
  }
}

.login-form {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  h1 {
    color: $baseWhite;
    font-weight: 700;
    font-size: 30px;
  }

  .login-form-forgot {
    color: $baseWhite;
    text-align: left;
    font-size: 14px;
    padding: 6px 36px;
    cursor: pointer;
    position: relative;
    top: 0;

    &:hover {
      color: $baseRed;
    }
  }

  .signup_link {
    justify-content: center;
    display: grid !important;

    .login-form-signup {
      color: $baseRed;
      font-size: 16px;
      padding: 6px 36px;
      position: relative;
      top: 0;
      font-weight: 500;

      &:hover {
        color: #e95b6f;
      }
    }

    .login-form-signup_text {
      color: $text-color-white;
      font-size: 14px;
      padding: 6px 36px;
      position: relative;
      top: 0;
    }
  }

  //   .fgt-pswd {
  //     text-align: left;
  //     font-size: 14px;
  //     padding: 14px 36px;
  //     cursor: pointer;
  // }

  .ant-input {
    // height: 50px;
    // padding: 6.5px 18px;
    // border-radius: 30px;
    // background: rgba(255, 255, 255, 0.2);

    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    margin: 4px;
    width: 86%;
    color: #ffffffb5;
    font-size: 16px;

    &:hover,
    &:focus-visible {
      // outline: 1px solid gray;
      outline: none;
    }
  }



  .login-form-button {
    // height: 50px;
    // color: $baseRed;
    // border-color: $baseRed;
    // font-weight: 700;
    // font-size: $font-size-xxl;

    // &.ant-btn:hover,
    // &.ant-btn:focus,
    // &.ant-btn:active {
    //   color: $baseBlack;
    //   border-color: $baseRed;
    //   background: $baseRed;
    // }

    cursor: pointer;
    width: 86%;
    margin: 10px;
    height: 46px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: #ffffff00;
    border: 1px solid #ef314c;
    margin: 4px;
    font-weight: 600;
    color: white;
    font-size: 16px;

    &.ant-btn:hover,
    &.ant-btn:focus,
    &.ant-btn:active {
      color: $baseWhite;
      border-color: $baseRed;
      background: $baseRed;
      transition: .5s linear;
    }
  }

  .resendBtn {
    width: 37% !important;
    font-size: 14px !important;
    height: 20px !important;
    border: 0 !important;
    margin: 0 !important;
    color: #ef314c !important;
    padding-top: 0 !important;
    padding-left: 5px !important;
    margin-left: 10px !important;
    font-weight: 500 !important;
    background: none !important;

    &:hover {
      border: 0 !important;
      color: $baseWhite !important;
    }
  }

  .resert-psw {

    a,
    p {
      font-size: $font-size-lg;
    }
  }



  .ant-input-password {
    border: 0;
    width: 86%;
    border-radius: 25px;
    padding: 0;

    .ant-input {
      width: 100%;
      // margin-left: 10px;
      // margin-right: 10px;
      margin: 0;
      padding-left: 20px;
      padding-right: 40px;
    }

    .ant-input-suffix {
      position: absolute;
      right: 20px;
      top: 18px;

      .ant-icon {
        font-size: 16px;
        color: white;
      }

    }
  }

}

.top-bar-input {
  height: 32px;
  border: none !important;
  background: $input-bg;
  border-radius: 20px;
  min-width: 150px;
  padding: 0 15px;

  .ant-input {
    border: none !important;
    background: transparent !important;
    // height: 32px;
    border-radius: 0 !important;
  }

  .anticon-close {
    font-size: 12px;
  }

}

// .ant-form-item-explain,
// .ant-form-item-explain-connected {
//   position: absolute;
//   margin: -12px;
//   padding-left: 45px;
//   color: #a61d24;
//   transition: color .3s cubic-bezier(.215, .61, .355, 1);
// }

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.very-number {
  .ant-input {
    width: 45px;
    border-radius: 15px;
  }
}

//coming soon
.coming-soon {
  background: $card-bg-white;
  width: 540px;
  padding: 40px 20px;
  border-radius: 14px;
  text-align: center;

  position: absolute;
  top: 30%;


  h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
  }

  .dot {
    color: $baseRed;
  }

  p {
    text-align: center;
    margin: 18px;
    font-weight: normal;

  }
}

//loading
.ant-spin {
  color: $baseWhite;
}

.ant-spin-dot-item {
  background-color: $baseRed;
}

//button
.refresh {
  background: transparent;
  color: #fff;
  border-color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border-color: #fff;
  }
}

.input-close {
  right: 15px;
  position: absolute;
  height: 20px;
  color: #ccc3c3;
}

.search-input {
  padding-right: 40px !important;
}

.user_photo {
  font-size: 46px;
  background: #FFFFFF99;
  border-radius: 50px;
  padding: 16px;
  color: #ef314c;
}

.change_profile_password {
  font-size: 16px;
  color: #ef314c;
  font-weight: 500;
  cursor: pointer;
}

.profilePassowrd_form {
  margin-top: 16px;

  .ant-input-password {
    border: 0;
    border-radius: 25px;
    padding: 0;
    width: 86%;
    height: 50px;

    .ant-input {
      margin: 0;
      padding-left: 20px;
      padding-right: 40px;
      width: 100%;
    }

    .ant-input-suffix {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }

  .profile_password_button {
    width: 86%;
    background: #ef314c;
    color: white;
    font-weight: 500;
    border: 1px solid #ef314c;
    transition: .3s linear;

    &:hover {
      background: transparent !important;

    }
  }
}

.close_password {
  background: #ef314c;
  border-radius: 25px;
  padding: 4px;
  font-size: 10px;
  position: relative;
  left: 6px;
  bottom: 4px;
  color: white;
}


.profilePassowrd_form {
  overflow: hidden;
  transition: height 1s, width 1s, padding 1s, visibility 1s, opacity 0.5s ease-out;
}

/*Making No-background changes for auto complete (auto select input) username and answword*/

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}


@media (max-width: 1400px) {

  //silder
  .flexslider {
    .rec-arrow {
      height: 96px;
    }
  }

  .slides {
    height: 96px;
  }

  .create-project {
    height: 96px;
  }

  // tab
  .custom-tab {
    .ant-tabs-tab {
      font-size: $font-size-base;
    }
  }

  .tab-content-title {
    h4 {
      font-size: $font-size-lg;
    }

    span {
      line-height: normal;
      font-size: small;
    }
  }

  .custom-card {
    h2 {
      font-size: $font-size-xxl;
    }

    &.card-height {
      height: 84px;
    }
  }
}

@media (max-width: 1200px) {

  //silder
  .flexslider {
    .rec-arrow {
      height: 80px;
    }
  }

  .slides {
    height: 80px;
  }

  .create-project {
    height: 80px;
  }

  // tab
  .tab-content-title {
    h4 {
      font-size: $font-size-base;
    }
  }

  .custom-card {
    padding: 10px;

    h2 {
      font-size: $font-size-xl;
    }

    span {
      font-size: 12px;
    }

    &.card-height {
      height: 75px;
    }
  }
}

.resetPassword {
  font-size: $font-size-lg;
  color: $baseWhite;
}

//projectListHeader

.client-select {

  .ant-select-selection-item {
    position: absolute !important;
    padding: 5px !important;
    color: white;
  }

  .ant-select-selector {

    border: none !important;
    background: #FFFFFF1A !important;
    height: 38px !important;

    &:focus-visible {
      outline: none !important;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      top: 4px;
    }

    .ant-select-selection-search {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 0;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border-radius: 2px;
      transition: all 0.3s;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ant-select-selection-search-input {
      padding: 6.5px 0px !important;
      font-size: 16px;
      height: 32px !important;
      color: $baseWhite;
    }
  }
}

//
.pricing-select {
  .ant-select-selection-item {
    position: absolute !important;
    padding: 5px !important;
    color: white;
    border-radius: 10px;
  }

  .ant-select-selector {
    border-radius: 25px !important;
    border: none !important;
    background: #FFFFFF1A !important;
    height: 32px !important;

    &:focus-visible {
      outline: none !important;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      top: 1px;
    }

    .ant-select-selection-search {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 2px 0;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: transparent;
      background-image: none;
      border-radius: 2px;
      transition: all 0.3s;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ant-select-selection-search-input {
      padding: 6.5px 0px !important;
      font-size: 16px;
      height: 32px !important;
      color: $baseWhite;
    }
  }
}

//pricing collapse
.collapsible-table {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .ant-collapse {
    background-color: #210408;
    border-radius: 10px;
    border: none;
    padding-top: 5px !important;

    .ant-collapse-header {
      margin-top: 2px;
      font-weight: 500;
      font-size: 16px;
      color: $baseWhite;
      padding: 10px;
      background-color: #291A1C;
      border-radius: 10px;
      margin: 10px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(75, 73, 73);
      }
    }

    .ant-collapse-item {
      margin-bottom: 2px;
      border-bottom: none;

    }

    .ant-collapse-content {
      background-color: #210408;
      border: none;

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 10px;
  }

  .project-header {
    .ant-collapse {
      height: calc(100vh - 225px) !important;
      overflow: auto;
    }

    .spread-header {
      .ant-collapse-header {
        // padding-left: 35px;
        border-radius: 10px;
      }

      // margin-top: -14px;
      // margin-bottom: -9px;
      height: 100% !important;

      .ant-collapse {
        .ant-collapse-item {
          // margin-bottom: -12px; 
        }

        // margin-top: -5px;
      }

      .ant-collapse-item {
        margin-left: 60px;
        // padding-bottom: 0px;
      }

      .ant-collapse-item-active {
        // padding-bottom: 0px;
      }

      .ant-collapse-content-active {
        // margin-bottom: -10px !important;
      }

      .panel-active .ant-collapse-header {
        background-color: #EF314C !important;
      }

      .spread-panel {
        margin-bottom: 10px;
      }

    }
  }

  .contractor-header {
    .ant-collapse {
      height: calc(100vh - 225px) !important;
      overflow: auto;
    }

    .ant-collapse-item-active .ant-collapse-header {
      background-color: #EF314C !important;
    }
  }


  .ant-col {
    margin-bottom: 20px;
  }

  .ant-collapse-header-text {
    padding-top: 3px;
  }
}

//proposals card
.proposal-list {
  background-color: #210408;
}

.proposal-card {
  $card-bg-color: #210408;
  $text-color: #ffffff;
  $header-icon-size: 20px;
  $spacing-sm: 4px;
  $spacing-md: 8px;

  margin-bottom: $spacing-md;
  border-radius: 10px;
  border: none;

  .ant-card-body {
    padding: $spacing-md;
    // background-color: transparent;
    background-color: #291A1C;
    color: $text-color;
    border-radius: 10px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;

    .action-icons {
      display: flex;
      justify-content: space-between;
    }

    .ellipsis-menu {
      font-size: $header-icon-size;
      color: $text-color;
      cursor: pointer;
    }

    .view-icon {
      font-size: $header-icon-size;
      color: $baseRed;
      cursor: pointer;
    }

    .update-icon {
      font-size: $header-icon-size;
      color: $baseRed;
      cursor: pointer;
    }

    .delete-icon {
      font-size: $header-icon-size;
      color: $baseRed;
      cursor: pointer;
    }

    .icons {
      .anticon {
        font-size: $header-icon-size;
        margin-left: $spacing-md;
        cursor: pointer;

        &.star-icon {
          color: $baseRed;
        }

        &.excel-icon {
          color: $baseRed;
        }
      }
    }
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .info-column {
      width: 90%;
      padding: 5px;
    }

    .info-item {
      margin-bottom: $spacing-md;
      text-align: center;
      display: flex;
      flex-direction: column;

      .label {
        font-size: 14px;
        margin: 0;
        background: transparent;
        color: $baseRed;
        text-align: center;
      }

      .value {
        // opacity: 0.7;
        // background: #291A1C;
        // border-radius: 10px;
        font-size: 14px;
        text-align: center;
        padding: 2px;
        margin: 0;
      }
    }
  }
}

.ant-collapse-arrow {
  color: white;
  font-size: 16px;
  border: none;
  padding: 5px;
  border-radius: 50%;
  background-color: $input-bg;
}

//pricing 9 dot icon animations
.ant-popover {
  .ant-popover-placement-top {
    top: 196px
  }
}

.ant-popover-placement-right {
  padding-left: 4px;
}

.ant-popover-inner {
  border-radius: 6px;
  background: #230503;
  padding-top: 5px;

  .ant-popover-inner-content {
    padding: 5px 12px 12px 12px;
    // top: 183px;
    top: 185px;
  }
}

.NavigationMenu {
  padding: 0;
  border-radius: 50%;

  .icon-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $baseRed;
  }
}

//table-action-column
.table-action-column {

  .ant-table-placeholder {
    background: transparent !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background: transparent !important;
    }

    .ant-table-cell {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent !important;

      // z-index: 1000;
      &:hover {
        background: transparent !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: #39201d !important;
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: transparent !important;
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      // &:nth-child(odd) {
      //   background: #ef314c26 !important;
      // }
    }
  }

  tr:nth-child(even) {
    td:last-child {
      // border-top-right-radius: 30px;
      // border-bottom-right-radius: 30px;
    }

    td:first-child {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: #39201d !important;
    }

    &:hover>td:last-child {
      // background: #39201d !important;
    }
  }

  tr:nth-child(odd) {
    td:last-child {
      // border-top-right-radius: 0;
      // border-bottom-right-radius: 0;
    }

    td:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #100002 !important;
    }

    &:hover>td:last-child {
      // background:  #39201d !important;
    }
  }

  tr:first-child {
    td:last-child {
      background-color: transparent !important;

      &:hover {
        background: transparent !important;
      }
    }

    &:hover>td:last-child {
      background: transparent !important;
    }
  }
}

//ag grid raw crc pm data table
.ag-header {
  position: sticky;
  top: 0;
  background-color: transparent;
  /* Adjust as needed */
  color: $baseRed;
  font-weight: bold !important;
}

.total-group-row {
  background-color: #f0fff8;
  /* Light blue background */
  color: #00008b;
  /* Dark blue text */
  font-weight: bold;
}

.pivot-total-value {
  background-color: lightgray;
  /* Light Gray background */
  font-size: 16px;
  /* Larger font size */
  font-weight: bold;
  /* Bold text */
  color: black;
  /* Black text */
}



// .raw-pagination{
//   border: none;
// }
.raw-pagination {
  color: $baseWhite;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: transparent;
  // border-top: none !important;
  // border-top: 1px solid #f0f0f0;
  border: 1px solid #f0f0f0;

  .pagination-info {
    font-size: 14px;
    padding-top: 5px;

    p {
      margin: 0;
    }
  }
}

.ag-pagination {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: white;
  /* Adjust as needed */
  padding: 10px;
}

//
.ag-theme-material-dark .ag-cell-inline-editing {
  background-color: #2C1A1D;
  height: auto !important;
  border: none !important;
  border-radius: 7px !important;
}

//pivot sidebar
.ag-theme-material-dark {
  --ag-control-panel-background-color: transparent !important;
  --ag-side-button-selected-background-color: #CC222244;

  --ag-selected-tab-underline-color: deeppink;
  --ag-selected-tab-underline-width: 2px;
  --ag-selected-tab-underline-transition-speed: 0.5s;
  --ag-toggle-button-off-background-color: white;
  --ag-toggle-button-on-background-color: blue;
  --ag-toggle-button-switch-background-color: white;

  // --ag-side-bar-panel-width: 300px;
  .ag-column-drop-cell {
    // background-color: purple;
    background-color: $baseRed;
    color: $baseWhite;
  }
}

.ag-theme-material-dark .ag-menu {
  background-color: #393939;
  min-width: 250px;
}

.custom-grid-theme {
  // --ag-foreground-color: $baseRed;
  --ag-background-color: transparent !important;
  --ag-header-foreground-color: $baseRed;
  --ag-header-background-color: rgb(209, 64, 129);
  //
  // --ag-selected-row-background-color: lightGray;
  --ag-odd-row-background-color: #39201d;
  --ag-row-hover-color: transparent;
  --ag-range-selection-background-color: #39201d;
  --ag-borders-input: none;
  --ag-input-focus-box-shadow: #39201d;
  --ag-checkbox-checked-color: $baseRed;
  --ag-modal-overlay-background-color: transparent;
  --ag-font-size: 12px;
  --ag-font-family: monospace;
  --ag-header-cell-hover-background-color: transparent;

  .ag-root {
    background: transparent !important;
  }

  .ag-root-wrapper {
    background-color: transparent !important; // Table body background
    color: $baseWhite;
  }

  .ag-input-wrapper,
  .ag-picker-field-wrapper {
    background-color: #2C1A1D;
    color: $baseWhite;
    border-radius: 7px;

    &:focus {
      border: none;
    }
  }

  .ag-header-cell-resize {
    background-color: $baseWhite;
    opacity: 0.5;
    width: 4px;
    top: 15px;
    height: 50%;
  }

  .ag-floating-filter {
    background-color: transparent !important;

    .ag-floating-filter-input {
      input {
        background-color: #2C1A1D;
        border-radius: 7px;
        width: 100%;
        padding: 0px 12px;
        color: $baseWhite;

        &:focus {
          // border-color: #80bdff; // Border color on focus
          // outline: none; // Remove default outline
          // box-shadow: 0 0 4px rgba(0, 123, 255, 0.25); // Optional: Add focus shadow
        }
      }
    }
  }

  .ag-header-cell {
    // background-color: transparent; // Header background color
    color: $baseRed; // Header text color
    font-size: 14px; // Header text size
  }

  //   .ag-cell, .ag-header-cell {
  //     // border-color: #d1d1d1; // Border color for cells and headers
  //     color: $baseWhite;
  //   }

  .ag-row {
    color: $baseWhite;
    font-size: 12px;
    background-color: #190003;
    border-bottom: 1px solid #414041;

    &:nth-child(even) {
      // border-top-left-radius: 30px;
      // border-bottom-left-radius: 30px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .ag-filter {
    // background-color: #e0e0e0; // Filter field background color
    color: $baseWhite; // Filter field text color
    font-size: 12px; // Filter text size
  }

  //   .ag-theme-material .ag-ltr .ag-cell {
  //     border-width: 1px 1px 1px 0;
  //   }

  //   .ag-theme-material .ag-ltr .ag-cell.ag-cell-focus {
  //     border-color: #2196f3 !important; // Focused cell border color
  //   }
}


//project margin card
.custom-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

//emaa dashboard
.chart-dashboard {
  display: flex;
  flex-direction: column;
  // gap: 16px;
}

.chart-container {
  position: relative;
  // margin: 20px;
  width: 100%;
  height: 38vh;
  // padding: 10px;
  // border: 1px solid #A9A9A9;
  border-radius: 5px;
  // background-color: #fff;
  background-color: transparent !important;
}

.chart-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // background-color: #fff;
  // background-color: transparent !important;
  padding: 20px;
  overflow: auto;
}

.chart-container.full-width {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  // background-color: #fff;
  background-color: transparent !important;
  padding: 20px;
  overflow: auto;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-color: $baseRed;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.chart-button {
  position: absolute;
  top: 10px;
  right: 53px;
  z-index: 10000;
  background-color:  $baseRed;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.shrink-button {
  top: 10px;
  right: 10px;
}

.toggle-button:hover {
  background-color: #0056b3;
}

//
.align-label-radio {
  display: flex;
  align-items: center;
}

.align-label-radio .ant-form-item-label {
  margin-right: 10px;
  /* Adjust as needed */
}

.align-label-radio .ant-radio-group {
  display: flex;
  align-items: center;
}

//carousal pagination

.rec-pagination {
  padding: 2px 10px;
  background: #58484a;
  position: absolute;
  top: 4px;
  right: 110px;
  border-radius: 15px;

  .rec-dot_active {
    background-color: #dc35454d !important;
    box-shadow: 0 0 1px 3px #dc3545 !important;
  }

  .rec-dot_active:hover,
  .rec-dot_active:focus {
    cursor: pointer;
    box-shadow: 0 0 1px 3px #dc3545 !important;
  }

  .rec-dot {
    background-color: transparent;
    box-shadow: 0 0 1px 2px #00000080;
  }

  .rec-dot:hover,
  .rec-dot:focus {
    cursor: pointer;
    box-shadow: 0 0 1px 3px #dc3545;
  }
}

.date-picker {
  .ant-picker {
    background: $input-bg !important;
  }
}

// add test in ITP
.add-test {
  position: relative;
  left: 20px;
  bottom: 10px;
  font-weight: 500;

  .new-test {
    position: relative;
    margin: 0 0 0 auto;
    padding-right: 20px;
  }

  .add-new-test {
    // position: absolute;
    // right: 36%;
    cursor: pointer;
    transition: .2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-20 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

.itp-overflow {
  overflow-y: hidden !important;

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}




.itp_carousal {

  .rec-arrow-right:hover:enabled {
    color: #fff !important;
    background-color: $baseRed !important;
    box-shadow: 0 0 2px 0 #333 !important;
  }

  .rec-arrow-left:hover:enabled {
    color: #fff !important;
    background-color: $baseRed !important;
    box-shadow: 0 0 2px 0 #333 !important;
  }
}


.statement_work,
.logDetails_table {

  .ant-table-placeholder {
    background: transparent !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background: transparent !important;
    }

    .ant-table-cell {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent !important;

      // z-index: 1000;
      &:hover {
        background: transparent !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: #39201d !important;
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: #3f0a0c !important;
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      // &:nth-child(odd) {
      //   background: #ef314c26 !important;
      // }
    }
  }

  tr:nth-child(even) {
    td:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #39201d !important;
    }

    td:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover>td:last-child {
      background: #39201d !important;
    }
  }

  tr:nth-child(odd) {
    td:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #3f0a0c !important;
    }

    td:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover>td:last-child {
      background: #3f0a0c !important;
    }
  }

  tr:first-child {
    td:last-child {
      background-color: transparent !important;

      &:hover {
        background: transparent !important;
      }
    }

    &:hover>td:last-child {
      background: transparent !important;
    }
  }
}

// .logDetails_table {

//   tr:nth-child(odd) {

//     td.ant-table-cell-fix-left:nth-child(odd),
//     td.ant-table-cell-fix-left:nth-last-child(odd) {
//       background: #39201d !important;
//     }
//   }

//   tr:nth-child(even) {

//     td.ant-table-cell-fix-left:nth-child(even),
//     td.ant-table-cell-fix-left:nth-last-child(even) {
//       background: #3f0a0c !important;
//     }
//   }

//   .ant-table-tbody tr.ant-table-row:first-child(even) {
//     background: #39201d !important;
//   }

//   .ant-table-tbody tr.ant-table-row:first-child(odd) {
//     background: #3f0a0c !important;
//   }

//   .ant-table-tbody tr.ant-table-row:nth-child(even) {
//     td:nth-last-child(2) {
//       background: #39201d !important;
//     }

//   }

//   .ant-table-tbody tr.ant-table-row:nth-child(odd) {
//     td:nth-last-child(2) {
//       background: #3f0a0c !important;
//     }
//   }

//   tr:nth-child(even) {

//     td:first-child,
//     td:nth-last-child(2) {
//       border-top-left-radius: 0;
//       border-bottom-left-radius: 0;
//       background-color: #39201d !important;
//     }

//     &:hover>td:first-child,
//     td:nth-last-child(2) {
//       background: #39201d !important;
//     }
//   }

//   tr:nth-child(odd) {

//     td:first-child,
//     td:nth-last-child(2) {
//       background-color: #3f0a0c !important;
//       border-top-left-radius: 0;
//       border-bottom-left-radius: 0;
//     }

//     &:hover>td:first-child,
//     td:nth-last-child(2) {
//       background: #3f0a0c !important;
//     }
//   }
// }

.logDetails_table {
  tr:nth-child(even) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;

      &:hover {
        background: #39201d !important;
      }
    }
  }

  tr:nth-child(odd) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;

      &:hover {
        background: #3f0a0c !important;
      }
    }
  }

  tr.ant-table-row:hover:nth-child(odd) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #3f0a0c !important;
    }
  }

  tr.ant-table-row:hover:nth-child(even) {

    td.ant-table-cell-fix-left,
    td.ant-table-cell-fix-right {
      background: #39201d !important;
    }
  }
}


.ant-table-ping-right .ant-table-cell-fix-right-first::after {
  box-shadow: none !important;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none !important;
}

// .log-details {
.logTabel-modal {
  width: 90% !important;
}

.equipTable-modal {
  width: 80% !important;
}


.maitenance-modal {
  width: 60% !important;

  .add_new_maintenance {
    .ant-form-item {
      margin: 0;
    }

    .addIcon {
      padding: 6px;
      margin: 3px;
      background: $baseRed;
      border-radius: 25px;
      cursor: pointer;
      transition: .2s linear;
      border: 1px solid $baseRed;
      margin-right: 6px;

      &:hover {
        background: transparent;
      }
    }

    .cancelIcon {
      padding: 6px;
      margin: 3px;
      background: #34343e;
      border-radius: 25px;
      cursor: pointer;
      transition: .2s linear;
      border: 1px solid #34343e;

      &:hover {
        background: transparent;
      }
    }
  }

  .add_maintenance {
    text-align: end;

    .child {
      padding: 3px 10px;
      background: #ef314c;
      border-radius: 25px;
      color: white;
      cursor: pointer;

      .anticon {
        position: relative;
        bottom: 3px;
      }
    }
  }
}

.logTabel-modal,
.equipTable-modal {
  // width: 90% !important;
  // height: 96%;
  overflow: hidden;
  border-radius: 25px;

  .ant-modal-header .ant-modal-title {
    font-weight: 600;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: #622d35 !important;
        // background: #ef314c38 !important;
        border-bottom: none !important;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 400 !important;
        color: $baseRed;
        padding: 10px;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: none;
  }

  .ant-table-tbody tr.ant-table-row td {
    padding: 10px;
  }

  .like,
  .unlike,
  .gradeOut,
  .pictureIcon,
  .gradeOutPictureIcon {
    border-radius: 25px;
    border: 1px solid;
    padding: 5px;
  }

  .like {
    color: #7eff12;
    background: #00000094;
  }

  .unlike {
    color: #ff0000;
    background: #00000094;
  }

  .gradeOut {
    color: $Grey;
    background: #00000094;
  }

  .pictureIcon {
    color: $baseRed;
    background: #00000094;
  }

  .gradeOutPictureIcon {
    color: $Grey;
    background: #00000094;
  }

  .pending {
    border-radius: 25px;
    padding: 4px;
    color: #d29f11;
    background: #00000094;
    border: 1px solid;
  }
}

// }
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-size: 14px !important;
}

.inspection-logs {
  position: relative;
  margin: 0 0 0 auto;
  padding-right: 20px;
  display: inline-flex;



  .log-filter {
    font-size: 20px;
    margin: 0 18px;
    cursor: pointer;
  }

  .ant-btn {
    background: #ef314c;
    border-radius: 25px;
    font-weight: 500;
    transition: .2s linear;
    border: 1px solid $baseRed;

    &:hover {
      border: 1px solid $baseRed;
      background: transparent !important;
      color: $baseRed;
    }

    &:focus,
    &:active {
      color: $baseWhite;
    }
  }
}

.team_tabs {
  .btn_left {
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn_right {
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .btn_left,
  .btn_right {
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: .2s linear;
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    color: #ef314c;
    height: 40px;

    &:active,
    &:hover,
    &:focus {
      color: White;
    }
  }

  .btnActive {
    color: $baseWhite !important;
    background: #ef314c;
    border: 1px solid #ef314c;
  }
}


.new-project-team {
  width: 150px;
  margin: 0 0 0 auto;

  .add-new-team {
    cursor: pointer;
    transition: .2s linear;
    font-size: 14px;

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

.addTeamDetails {


  .pipeline-table {
    padding-bottom: 10px;
  }

  .ant-table-selection-col {
    width: 100px;
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: transparent;
  }

  .new-team {
    // position: relative;
    // margin: 0 0 0 auto;
    // padding-right: 20px;
    text-align: left;
    padding-left: 20px;
  }

  .add-new-team {
    cursor: pointer;
    transition: .2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }

  .equipPaginationTable {
    .ant-table-pagination.ant-pagination {
      padding: 16px;
      margin: 0;
      background: transparent;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      // position: absolute;
      left: 50;
      right: 0;
      bottom: 00px;
    }

    tr.ant-table-expanded-row>td,
    tr.ant-table-expanded-row:hover>td {
      background: transparent;
    }


    .ant-pagination-item-active:hover,
    .ant-pagination-item-active,
    .ant-pagination-item:hover,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #ef314c;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-item:hover a {
      color: #ef314c;
    }
  }

}


.addTeamDetails3 {


  .pipeline-table {
    padding-bottom: 10px;
  }

  .ant-table-selection-col {
    width: 100px;
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }

  .ant-table-expanded-row:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }

  .ant-table-expanded-row:nth-child(odd) {
    background: rgba(255, 255, 255, 0.1)
  }

  .new-team {
    // position: relative;
    // margin: 0 0 0 auto;
    // padding-right: 20px;
    text-align: left;
    padding-left: 20px;
  }

  .add-new-team {
    cursor: pointer;
    transition: .2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }

  .equipPaginationTable {
    .ant-table-pagination.ant-pagination {
      padding: 16px;
      margin: 0;
      background: transparent;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      position: absolute;
      left: 50;
      right: 0;
      bottom: -55px;
    }

    tr.ant-table-expanded-row>td,
    tr.ant-table-expanded-row:hover>td {
      background: transparent;
    }

    .ant-pagination-item-active:hover,
    .ant-pagination-item-active,
    .ant-pagination-item:hover,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #ef314c;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-item:hover a {
      color: #ef314c;
    }
  }

}

// To remove lines (border) for table head
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: none;
}


.inventory {
  height: calc(100vh - 350px) !important;
}


.report-radio {

  .ant-radio-inner::after {
    background-color: #ef314c;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #ef314c;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: #ef314c !important;
  }

  .ant-form input[type='radio']:focus {
    outline: none !important;
  }
}

.report-radio2 {

  .ant-radio-inner::after {
    background-color: gray;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: gray;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: gray !important;
  }

  .ant-form input[type='radio']:focus {
    outline: none !important;
  }
}

.report-checkbox {

  .ant-radio-inner::after {
    background-color: #ef314c;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #ef314c;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: #ef314c !important;
  }

  .ant-form input[type='radio']:focus {
    outline: none !important;
  }
}

.report-checkbox2 {

  .ant-radio-inner::after {
    background-color: gray;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: white;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: gray;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: gray !important;
    background-color: gray;
  }

  .ant-form input[type='radio']:focus {
    outline: none !important;
  }
}

.filter-logs {
  .filter-none {
    height: 32px;
    border: none !important;
    background: $input-bg;
    border-radius: 20px;
    min-width: 200px;
    padding: 7px 15px;
    position: relative;
    top: 6px;
    font-weight: 500;
  }

  .top-bar-input {
    height: 32px;
    border: none !important;
    background: $input-bg;
    border-radius: 20px;
    min-width: 220px;
    padding: 0 15px;

    .ant-input {
      border: none !important;
      background: transparent !important;
      // height: 32px;
      border-radius: 0 !important;
    }

    .anticon-close {
      font-size: 12px;
    }
  }
}

.notfound {
  color: #177ddc;
  font-size: '16px';
  font-weight: 500;

  &:hover {
    color: #ef314c !important
  }
}

.ant-input-number {
  border-radius: 25px;
  width: 100%;
  background: $input-bg;
  border-color: transparent;
  box-shadow: none;
  height: 38px;

  &:hover {
    border-color: transparent;
  }

  &:focus {
    box-shadow: none !important;
  }
}


.log-calender {

  .ant-picker-calendar-header {
    background: #281a1b;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    background: #281a1b;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: $baseWhite;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #330f14;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #ef314c;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 25px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ef314c !important;
    border-right-width: 1px !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #ef314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #ef314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #ef314c;
    background: transparent;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #ef314c;
    border-color: #ef314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #ef314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ef314c;
  }

  .ant-radio-button-wrapper:hover {
    color: #ef314c;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper:focus-within {
    box-shadow: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.log-calender {

  .events,
  .notes-month {
    background: #ef314c;
    border-radius: 3px;
    padding: 0 5px;
    font-weight: 500;
  }

  .ant-picker-calendar-header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 500;
    border-bottom: 1px solid #707070;
    // justify-content: center;
    padding-right: 10px;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    position: relative;
    top: 50px;
  }

  .ant-picker-calendar {
    overflow-y: scroll;
    height: calc(100vh - 420px);
    // box-shadow: 0 0 8px 0px #141414;
    background: transparent;
  }

  .ant-select-dropdown {
    height: 220px;
  }
}

.log-select-pipeline {
  position: fixed;
  z-index: 1000;
  margin: 8px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 25px;
  }
}

.itp-weld {
  position: relative;
  top: 3px;
  left: 10px;

  .ant-form-item {
    margin: 0 !important;

    .ant-input-lg {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .ant-input {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  // .example {
  //   // position: absolute;
  //   // right: 50px;
  //   // top: 6px;
  //   // padding: 0 !important;
  //   // padding-top: 6px !important;

  //   .ant-typography {
  //     font-weight: 500;
  //     letter-spacing: 0.6px;
  //   }
  // }

  .weld-icon svg {
    border-radius: 25px;
    margin: 3px;
    padding: 6px;
    font-size: 28px;
    cursor: pointer;
    transition: .3s linear;

  }

  .add svg {
    background: #ef314c;
    border: 1px solid #ef314c;


    &:hover {
      background: #ef314c69;
      border: 1px solid #ef314c69;
    }


  }

  .close svg {
    background: transparent;
    border: 1px solid #323232;

    &:hover {
      background: #323232;
      border: 1px solid #323232;
    }
  }
}

.itp-select {
  .ant-select {
    // width: 170px;
    margin: 2px 0px 0;
  }

  .ant-select-selection-item {
    width: 80%;
    font-weight: 500;
    font-size: 14px;
  }

  .ant-select-selector {
    border-radius: 25px !important;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .ant-select-arrow {
    padding-top: 8px !important;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }

}

.ant-select-selection-item {
  width: 80%;
}

.station_name {
  display: inline-flex;
  height: 26px;

  .station_name_ {
    font-weight: 700;
    font-size: 16px;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .edit_station_name {
    font-size: 16px;
    color: #ef314c;
    position: relative;
    // left: 10px;
    top: 6px;
    cursor: pointer;
  }

  .edit_station_input {
    width: 60% !important;
  }

  .edit_close_icon,
  .edit_apply_icon {
    background: #311f1e;
    border-radius: 25px;
    font-size: 10px;
    padding: 9px;
    position: relative;
    bottom: 3px;
    transition: .2s linear;
  }

  .edit_close_icon {
    &:hover {
      background: #212529;
    }
  }

  .edit_apply_icon {
    left: 5px;
    background: $baseRed;

    &:hover {
      background: #ef314ca8;
    }
  }


}

.test_text {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.station_container {
  height: 120px;
  position: relative;
  cursor: pointer;
}

.delete_sow_station {
  position: absolute;
  top: 2px;
  bottom: 20px;
  left: 14%;
  right: 14%;
  height: 100%;
  width: 70%;
  opacity: 0;
  transition: .3s ease;
  background-color: #ef314cb5;
  border-radius: 4px;
}

.station_container:hover .delete_sow_station {
  opacity: 1;
}

.delete_station_icon {
  color: $baseRed;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  transition: .3s linear;
  border-radius: 25px;
  padding: 11px;
  background-color: whitesmoke;

  &:hover {
    color: $baseWhite;
    background: #111b26ad;
  }
}

.no_image {
  color: #ef314c;
  background: #281a1b;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 3px;
  padding: 6px 20px;
  font-weight: 600;
  /* color: white; */
  // background: #ef314cb5;
}

.delete_station_list_icon {
  color: #ef314c;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 6px;
  background: #2b2021;
  border-radius: 25px;
  padding: 4px;
  transition: .3s linear;

  &:hover {
    background: #dee2e6;
  }
}

.ant-select-selection-item {
  .anticon-delete {
    display: none;
  }
}

.calender_header {
  z-index: 1000;
  position: absolute;
  right: 0;
  left: 0;
  background: #281a1b;
  padding: 6px;

  .ant-form-item {
    margin: 0;
  }

  .year,
  .month {
    .ant-select-selection-item {
      left: 0;
      top: 2px;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      left: 12px;
      top: 8px;
    }
  }

  .calender_button_header {
    text-align: left;
    top: 2px;

    .calender_btn {
      border-radius: 25px;
      background: #ef314c;
      height: 36px;
      border: none;
      width: 50%;
      font-weight: 500;
      transition: .3s linear;
      color: $baseWhite;

      &:hover {
        color: $baseWhite;
        background: #34343e;

      }
    }
  }
}

.calheight {
  overflow: hidden !important;
}

.view {
  .ant-picker-panel {
    display: block;
  }
}

.hide {
  .ant-picker-panel {
    display: none;
  }
}

.calTable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.log-table {

  ::-webkit-scrollbar-thumb {
    background-color: rgb(122, 122, 122);
    outline: 1px solid rgb(92, 92, 92);
    border-radius: 10px;

    // background-color: #ef314cba;
    //   outline: #ef314cba;
    //   border-radius: 10px;
  }

  .ant-table-body {
    position: relative;
    top: 10px;
  }



  .ant-table-pagination.ant-pagination {
    position: absolute;
    padding: 10px;
    z-index: 10;
    bottom: -100px;
  }

  .ant-pagination-item-active:hover,
  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ef314c;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-item:hover a {
    color: #ef314c;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #ef314c;
  }

  .ant-pagination-prev,
  .ant-pagination-next {

    .anticon-left,
    .anticon-right {
      position: relative;
      bottom: 3px;
    }
  }
}

.dated {
  border-radius: 25px;
  background: #ef314c;
  padding: 3px 10px;
  position: absolute;
  top: 30px;
  right: 36px;
}

.verificationCode {
  input {
    height: 50px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    margin-right: 10px;
    margin-left: 10px;
    // padding-left: 12px;
    width: 30px !important;

    &:focus-visible {
      outline: 2px solid $baseRed;
    }
  }
}

.ant-form-item-feedback-icon {
  position: relative;
  bottom: 4px;
}

.profile_text {
  font-size: 16px;
  color: #FFFFFF99;
}

.equip_table {
  .ant-table-tbody {
    tr {
      // cursor: pointer;

      // &:hover {
      //   background: #ef314c5e !important;

      //   .edit-hover {
      //     color: $baseWhite !important;
      //   }
      // }
    }

    tr:nth-child(odd) {
      td:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      td:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }


  // tr:nth-child(even) {
  //   td:last-child {
  //     border-top-right-radius: 30px;
  //     border-bottom-right-radius: 30px;
  //     background-color: #39201d !important;
  //   }

  //   td:first-child {
  //     border-top-left-radius: 30px;
  //     border-bottom-left-radius: 30px;
  //   }

  //   &:hover>td:last-child {
  //     background: #39201d !important;
  //   }
  // }

  // tr:nth-child(odd) {
  //   td:last-child {
  //     border-top-right-radius: 0;
  //     border-bottom-right-radius: 0;
  //     background-color: #39201d !important;
  //   }

  //       td:first-child {
  //         border-top-left-radius: 0;
  //         border-bottom-left-radius: 0;
  //       }
  //       td:first-child {
  //         border-top-left-radius: 0;
  //         border-bottom-left-radius: 0;
  //       }

  //   &:hover>td:last-child {
  //     background: #39201d !important;
  //   }
  // }
}

.equipment_logs {

  .equip_tabs {
    text-align: center;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 8px;
  }

  .ant-btn {
    border-radius: 25px;
    font-weight: 500;
    transition: .2s linear;
    border: transparent;
    font-weight: 600;
    color: $baseWhite;

    &:focus,
    &:active {
      color: $baseWhite;
    }
  }

  .active {
    border: 1px solid $baseRed;
    background: $baseRed !important;
  }

  .log-table .ant-table-pagination.ant-pagination {
    bottom: -55;
  }
}

.equip_note {
  position: absolute;
  right: 0;
  font-size: 10px;
  bottom: 0;
}


.calenderView {
  position: absolute;
  top: 20px;
  right: 36px;

  .ant-picker-suffix {
    color: $baseRed;
  }
}

.weekDate {
  position: absolute;
  top: 30px;
  right: 200px;
  background: $baseRed;
  border-radius: 25px;
  padding: 0 12px;
}

.performedDate {
  background: $baseRed;
  padding: 3px 12px;
  border-radius: 25px;
  position: relative;
  top: -10px;
}

.maintenance-select {
  .ant-select-selector {
    border-radius: 25px !important;
    border: none !important;
    background: #FFFFFF1A !important;
    height: 100% !important;
  }

  .ant-select-selection-overflow {
    padding: 6px 12px 6px 0px;

    .ant-select-selection-item {
      border-radius: 25px;
      color: $baseWhite;
      width: 96%;
      padding: 0px 10px;
    }
  }

  .ant-select-selection-overflow-item {
    // width: 20%;

    .ant-select-selection-item {
      // position: relative !important;
      // padding: 0 5px !important;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ef314c;
}

.imagePreview {
  // display: grid;
  // width: 200px;
  // overflow: auto;
  // height: calc(100vh - 260px);

  .ant-image-img {
    width: 100%;
    height: 200px;
    vertical-align: middle;
    // object-fit: cover;
    object-fit: contain;
    border-radius: 3px;
  }

  a {
    position: relative;
    top: 10px;
    display: flex;
    justify-content: center;
    width: 200px;

    .ant-typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .anticon {
      font-size: 20px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;
      color: #ef314c;
      background: #00000094;

      svg {
        padding: 3px;
      }
    }
  }
}


.admin {
  height: calc(100vh - 40px);

  header {
    .ant-typography {
      font-size: 20px;
    }
  }

  .ant-col {
    padding: 20px;
  }

  .homePage_cards {
    border-radius: 20px;
    // background: $card-bg-white;
    background: linear-gradient(90deg, #ef314c, #212529);
    border: none;
    // font-weight: 600;

    .ant-card-body {
      padding: 30px;
      font-size: 16px;
    }

    .anticon {
      color: $baseRed;

      &:hover {
        color: $baseWhite;
      }
    }

    .ant-card-actions {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #1e0d0f;
    }
  }


}





@media (min-width: 1281px) and (max-width: 1366px) {
  .view_station {
    // width: 350px;
    width: 400px;
  }
}

@media screen and (max-width: 1280px) {
  .view_station {
    // width: 350px;
    width: 400px;
  }
}

@media (min-width: 1367px) and (max-width: 1600px) {
  .view_station {
    width: 450px;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .view_station {
    width: 400px;
  }
}

@media (min-width: 1700px) and (max-width: 1920px) {
  .view_station {
    width: 420px !important;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }
}

@media (min-width: 1921px) {
  .view_station {
    width: 420px !important;
  }

  .main_station_height {
    height: calc(100vh - 420px) !important;

    .test_height {
      height: calc(100vh - 650px) !important;
    }
  }
}

@media(max-height: 760px) {
  .team_resolution {
    height: calc(100vh - 400px) !important;
  }
}



$blink : 'blink';

.blinkingDot {
  height: 8px;
  width: 8px;
  // background-color: limegreen;
  border-radius: 50%;
  opacity: 1;
}

.blinking {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  bottom: 6px;
  right: 2px;


  -webkit-animation: 1.4s #{$blink} ease infinite;
  -moz-animation: 1.4s #{$blink} ease infinite;
  -ms-animation: 1.4s #{$blink} ease infinite;
  -o-animation: 1.4s #{$blink} ease infinite;
  animation: 1.4s #{$blink} ease infinite;
}



@keyframes #{$blink} {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes #{$blink} {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes #{$blink} {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes #{$blink} {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes #{$blink} {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.back_equip {
  text-align: left;
  margin-top: 5px;
  display: flex;
  position: relative;

  .anticon {
    border-radius: 25px;
    padding: 6px;
    background: #ef314c;
    font-size: 18px;
    // width: 70px;
    height: 30px;
    cursor: pointer;
  }
}

.new-calibration {
  width: auto;
  margin: 0 0 0 auto;

  .add-new-calibration {
    cursor: pointer;
    transition: .2s linear;
    font-size: 14px;

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }
}

.addTeamDetails2 {


  .pipeline-table {
    padding-bottom: 10px;
  }


  .new-team {
    // position: relative;
    // margin: 0 0 0 auto;
    // padding-right: 20px;
    text-align: left;
    padding-left: 20px;
  }

  .add-new-team {
    cursor: pointer;
    transition: .2s linear;
    font-size: $font-size-base;

    &:hover {
      color: $baseRed;
    }

    .icon-22 {
      border: 2px solid;
      padding: 3px;
      border-radius: 25px;
      margin: -1px 5px;
      color: #dc3545;
    }
  }

  .equipPaginationTable {
    .ant-table-pagination.ant-pagination {
      padding: 16px;
      margin: 0;
      background: transparent;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      position: absolute;
      left: 50;
      right: 0;
      bottom: -55px;
    }


    .ant-pagination-item-active:hover,
    .ant-pagination-item-active,
    .ant-pagination-item:hover,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #ef314c;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-item:hover a {
      color: #ef314c;
    }
  }


}

.calibration-table {
  // padding: 0 30px;
  padding-bottom: 30px;

  .ant-table-tbody {
    tr {
      td {
        padding: 10px !important;
        font-size: 14px;
      }

      td:first-child {
        padding-left: 20px !important;
      }

      //   td:last-child {
      //     flex: 1;
      //     display:inline-block;
      // }
    }
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent;
        border-bottom: none;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 500 !important;
        color: $baseRed;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }
    }
  }

  .edit-hover {
    transition: .2s linear;
    color: $baseRed;
    // &:hover {
    //   color: $baseWhite;
    // }
  }
}

.searchEquip {
  margin: 0 1px;

  .ant-input-affix-wrapper {
    background: #FFFFFF1A !important;
    border-radius: 20px;
    border: none;

    .ant-input {
      background: transparent !important;
      border-radius: 0;
    }
  }
}

.searchUserManagement {
  margin: 0 20px;

  .ant-input-affix-wrapper {
    background: #FFFFFF1A !important;
    border-radius: 20px;
    border: none;

    .ant-input {
      background: transparent !important;
      border-radius: 0;
      width: 240px;
    }
  }
}

.custom-expand-icon {
  position: relative;
  right: 6px;
  top: -3px;
  color: #ef314c;
}

.pdfIcon:hover {
  color: $baseRed;
}

.pdfIcon:hover {
  color: $baseRed;
}

//
.heatp-bottom-border {
  border-bottom: 1px solid white;
}

.fixed-pagination {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  //background: red;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fixed-pagination2 {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 800;
  //background: red;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.pdfIconRed {
  color: #EF314C;
  background: transparent;
  border-radius: 25px;
  border: 1.5px solid;
  padding: 5px;
}

.gradepdfIconRed {
  color: #707070;
  background: #00000094;
  border-radius: 25px;
  border: 1.5px solid;
  padding: 5px;
}

.heatIcon {
  color: #EF314C;
  // border-radius: 25px;
  border: none;
  // padding: 3px;
}

.Tracker-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 46px;
    border: none;
    margin: 4px;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseWhite;
    ;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ef314c;
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }
}

@media (min-width: 992px) {
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.maintenance-select1 {
  .ant-select-selector {
    border-radius: 25px !important;
    border: none !important;
    background: #FFFFFF1A !important;
    height: 100% !important;
  }

  .ant-select-selection-overflow {
    //padding: 6px 12px 6px 0px;

    .ant-select-selection-item {
      border-radius: 25px;
      color: $baseWhite;
      width: 96%;
      padding: 0px 10px;
    }
  }

  .selected-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
    border-radius: 25px;
    color: $baseWhite;
    //background: #FFFFFF1A !important;
    // border: 1px solid #ccc;
    padding: 5px;
    max-height: 150px;
    /* Fixed height */
    overflow-y: auto;
    /* Enable vertical scroll */
  }



  .selected-tag {
    background: #FFFFFF1A !important;
    color: white;
    color: $baseWhite;
    padding: 5px 10px;
    margin: 2px;
    display: flex;
    border-radius: 25px;
    align-items: center;
  }

  .selected-tag .remove-tag {
    margin-left: 10px;
    cursor: pointer;
    color: #ef314c;
    ;
    //background: rgba(255, 255, 255, 0.2);
  }

  .ant-select-selection-overflow-item {
    // width: 20%;

    .ant-select-selection-item {
      // position: relative !important;
      // padding: 0 5px !important;
    }
  }
}

.prcing.Btn {
  .ant-btn {
    padding: 8px 20px;
    height: 40px;
    font-weight: 700;
    background: transparent;
    color: $baseWhite;
    border-color: $baseWhite;
    border-radius: 30px;
  }

  .ant-btn-primary {
    background: $baseRed;
    color: $baseWhite;
    border-color: $baseWhite;
  }
}

.pricing-regenerate-label {

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding-right: '25px';

  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding-left: '25px';
  }
}

.prcing-Table {

  .ant-table-tbody {
    tr {
      td {
        font-size: 12px;
      }
    }
  }

  .ant-table-thead tr th {
    color: #ef314c;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 0px 0px
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: transparent
  }

  .ant-table-tbody tr.ant-table-row:nth-child(even) {
    background: transparent
  }

  .ant-table-tbody tr.ant-table-row:nth-child(odd) {
    background: transparent
  }

  .ant-table-expanded-row:nth-child(even) {
    background: transparent
  }

  .ant-table-expanded-row:nth-child(odd) {
    background: transparent
  }
}

.Filter-Drawer {

  width: 100%;
  height: 100%;
  padding-top: 10%;
  overflow: auto;

  //background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  // background: 'rgba(0, 0, 0, 0)';
  .ant-drawer-body {
    padding: '5px'
  }

  .ant-btn {
    padding: 8px 20px;
    height: 40px;
    font-weight: 700;
    background: transparent;
    color: $baseWhite;
    border-color: $baseWhite;
    border-radius: 30px;
  }

  .ant-btn-primary {
    background: $baseRed;
    color: $baseWhite;
    border-color: $baseWhite;
  }

  .ant-drawer-content-wrapper {
    top: 70px;
  }

  .ant-drawer-content {
    width: 100%;
    height: 94%;
    overflow: auto;
    // background: rgba(0, 0, 0, 0);
    background: #281a1b;
    pointer-events: auto;
    border-radius: 30px;
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content-scrollable {
    flex: 1;
    overflow-y: auto;
  }

  .fixed-footer {
    position: sticky;
    bottom: 0;
    background: transparent;
    /* Background color to cover the underlying content */
    padding: 0px;
    //border-top: 1px solid #e8e8e8;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    /* Aligns buttons to the right */
    gap: 10px;
    /* Optional: Add gap between buttons */
  }
}

.data-display {
  margin: 0 auto;
  width: 80%;
  max-width: 600px;

  .section {
    margin-bottom: 20px;
  }

  h2 {
    text-align: left;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .label {
    text-align: left;
    font-weight: bold;
  }

  .value {
    text-align: right;
  }

}





.NavigationMenu {
  .main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 10px;
  }

  .main .navigation {
    position: relative;
    width: 30px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    padding-bottom: '50%';
  }

  .main .navigation span {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    //transform: translate(calc(0px * var(--x)), calc(0px * var(--y)));
    // transition: transform 0.5s, width 0.5s, height 0.5s, background 0.5s;
    //transition-delay: calc(0.1s * var(--i));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main .navigation.active span {
    width: 25px;
    height: 25px;
    background: #37384f;
    // transform: translate(calc(30px * var(--x)), calc(30px * var(--y)));
    //display: inline-block;
    transform: translate(calc(30px * var(--x)), 0);
    /* Horizontal movement only */
    transition: transform 0.3s ease;
    /* Smooth transition */
  }

  .main .navigation span ion-icon {
    transition: 0.5s;
    font-size: 0em;
    color: #fff;
  }

  .main .navigation.active span ion-icon {
    font-size: 1.35em;
  }

  .navigation.active span:hover ion-icon {
    color: #2dfc52;
    filter: drop-shadow(0 0 2px #2dfc52) drop-shadow(0 0 5px #2dfc52) drop-shadow(0 0 15px #2dfc52);
  }

  .close {
    position: absolute;
    width: 7px;
    height: 7px;
    background: #fff;
    transition: 0.5s;
    transition-delay: 0.4s;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main .navigation.active~.close {
    width: 40px;
    height: 40px;
    pointer-events: initial;
    transition-delay: 0.8s;
    background: #2dfc52;
  }

  .main .navigation~.close ion-icon {
    font-size: 2em;
    scale: 0;
    color: #10131c;
    transition: 0.5s;
  }

  .main .navigation.active~.close ion-icon {
    scale: 1;
    transition-delay: 1s;
  }
}